














































































































































































import Vue from "vue";
import ListingApplicants from "@/components/recruiter/jobs/job_listing/ListingApplicants.vue";
import ListingActions from "@/components/recruiter/jobs/job_listing/ListingActions.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  IS_MATCHING_CANDIDATES_EXIST,
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  MATCHING_CANDIDATES,
  JOB_LISTING_PAGINATION,
  JOB_LISTING_DATE_FILTER,
  JOB_LISTING_SEARCHED_JOB_FILTER
} from "@/store/modules/recruiter/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import {
  GetJobsPostedPayload,
  JobsWithCandidates
} from "@/store/modules/recruiter/interfaces";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "ListingTBody",
  data() {
    return {
      destroyed: false
    };
  },
  components: { ListingApplicants, DataNotFound, ListingActions },
  async mounted() {
    await this.extract_jobs(this.get_posted_jobs.pagination);
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      get_posted_jobs_loading: JOBS_POSTED_BY_RECRUITER_LOADING,
      get_posted_jobs: JOBS_POSTED_BY_RECRUITER,
      get_job_listing_date_filter: JOB_LISTING_DATE_FILTER,
      get_job_listing_searched_job_filter: JOB_LISTING_SEARCHED_JOB_FILTER
    })
  },
  watch: {
    get_job_listing_date_filter(n) {
      if (n.length === 2 || n.length === 0) {
        this.set_job_pagination(1);
        this.extract_jobs(this.get_posted_jobs.pagination);
      }
    },
    get_job_listing_searched_job_filter() {
      this.set_job_pagination(1);
      this.extract_jobs(this.get_posted_jobs.pagination);
    }
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_posted_jobs: JOBS_POSTED_BY_RECRUITER,
      get_matching_candidates: MATCHING_CANDIDATES,
      is_matching_candidates_exist: IS_MATCHING_CANDIDATES_EXIST
    }),
    ...mapMutations("recruiter", {
      set_job_pagination: JOB_LISTING_PAGINATION
    }),
    async job_removed() {
      await this.extract_jobs(this.get_posted_jobs.pagination);
    },
    /**
     * Function to pass job data to parent JobListing Component
     * @param job{Jobs} => a job to edit
     */
    async activate_edit_job_mode(job: Jobs) {
      await this.$router.push(`jobs/${job.jid}/edit`);
    },
    /**
     * Function to pass job data to parent JobListing Component
     * @param job{Jobs} => a job to edit
     */
    async activate_view_job_mode(job: Jobs) {
      // this.$emit("view_job", job);
      await this.$router.push(`jobs/${job.jid}`);
    },
    /**
     * Function to extract jobs from Backend using pagination
     * @param page
     * @param limit
     */
    async extract_jobs(page = 1, limit = 10) {
      const obj: GetJobsPostedPayload = {
        page: page - 1,
        limit_per_page: limit,
        dashboard: false
      };
      if (this.get_posted_jobs.date_filter.length === 2)
        obj.date_filter = this.get_posted_jobs.date_filter;
      if (this.get_posted_jobs.searched_job_title)
        obj.job_title = this.get_posted_jobs.searched_job_title.text;
      await this.fetch_posted_jobs(obj);
      // Fetching all the record that exist in DB
      const job_ids = this.get_posted_jobs.results.map((job: Jobs) => job.jid);
      await this.get_matching_candidates({ job_ids, dashboard: false });
      // Loop to fetch matching candidates from ML server if not exist in DB

      // Create job ids if candidate loading is true and not destroyed
      const _job_ids = this.get_posted_jobs.results
        .filter(
          (job: JobsWithCandidates) => job.candidates_loading && !this.destroyed
        )
        .map((job: JobsWithCandidates) => job.jid);
      if (_job_ids.length)
        await this.get_matching_candidates({
          job_ids: _job_ids,
          dashboard: false
        });
    },
    get_no_data_msg(): string {
      return this.$t("shared.no-data").toString();
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
});
