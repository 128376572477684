


































































































































import Vue, { PropType } from "vue";
import router from "@/router";
import { Jobs } from "@/interfaces/data_objects/jobs";
import DeleteItem from "@/components/shared/DeleteItem.vue";
import ShareItem from "@/components/shared/ShareItem.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  DELETE_JOB_SOFTLY,
  RECRUITER_LOADING
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { VIEW_JOB_STATE } from "@/store/modules/common/constants";
import { ViewJobState } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "ListingActions",
  components: {
    DeleteItem,
    ShareItem
  },
  data() {
    return {
      view_icon: require("@/assets/icons/linear/eye.svg"),
      update_icon: require("@/assets/icons/linear/edit.svg"),
      share_icon: require("@/assets/icons/linear/share.svg"),
      delete_icon: require("@/assets/icons/bold/trash.svg"),
      copy_icon: require("@/assets/icons/linear/share.svg"),
      delete_dialog: false,
      share_dialog: false,
      display_link_copied_snackbar: false
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      recruiter_loading: RECRUITER_LOADING
    })
  },
  props: {
    job: {
      type: Object as PropType<Jobs>,
      required: true
    }
  },
  methods: {
    ...mapMutations("common", {
      set_view_job_state: VIEW_JOB_STATE
    }),
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    ...mapActions("recruiter", {
      delete_job_softly: DELETE_JOB_SOFTLY
    }),
    /**
     * Function to close dialog or removing job
     * @param value {boolean}: dialog buttons pressed value
     */
    async dialog_action(value: boolean) {
      // If user press yes button
      if (value) {
        // Remove job
        const response = await this.delete_job_softly(this.job.jid);
        // If job removed successfully => show notification
        if (response) {
          this.root_notification(
            `${this.job.job_title} ${this.$t(
              "success-messages.removed-success"
            )}`
          );
          this.$emit("job_removed", 1, 10);
        } else {
          this.root_error(
            `${this.job.job_title} ${this.$t("errors.removed-failed")}`
          );
        }
        this.delete_dialog = !value; // Hide delete dialog
      } else this.delete_dialog = value;
    },
    transform_view_icon(icon: HTMLElement): HTMLElement {
      const color = "#3CE17E";
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    transform_update_icon(icon: HTMLElement): HTMLElement {
      const color = "#33BFFF";
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    transform_share_icon(icon: HTMLElement): HTMLElement {
      const color = "#FF8473";
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    transform_trash_icon(icon: HTMLElement): HTMLElement {
      const color = "#FF4066";
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    /**
     * Function to update view job state &
     * Navigate to view job details page
     */
    async view_job() {
      this.set_view_job_state(ViewJobState.JOB_LISTING);
      await router.push(`/job/${this.job.jid}`);
    }
    /**
     * Function to copy job link
     */
    // async copy_job_link() {
    //   let job_link = window.location.href.split("/").slice(0, 3).join("/");
    //   job_link += `/#/job/${this.job.jid}`;

    //   await navigator.clipboard.writeText(job_link);
    //   this.display_link_copied_snackbar = true;
    //   // setTimeout(() => {
    //   //   this.display_link_copied_snackbar = false;
    //   // }, 5000);
    // }
  }
});
